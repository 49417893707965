<script setup>
import Modal from "@/Components/Popup/Modal.vue";
import ApproveHoursForm from "@/Pages/Location/ApproveHours/Partials/ApproveHoursForm.vue";
import { inject } from "vue";

const dayjs = inject("dayjs");
const props = defineProps({
  showing: Boolean,
  selected_applicant: Object,
  shift: Object,
  hideNextButton: Boolean
});

const emits = defineEmits([
  "update:showing",
  "nextRowClicked",
  "markedAsNoShow"
]);
const closeForm = () => emits("update:showing", false);

const sendNextRow = payload => {
  emits("nextRowClicked", payload);
};
</script>

<template>
  <Modal :open="showing" class="sm:max-w-[800px]" @closing="closeForm">
    <template #default>
      <h2 class="text-[32px] font-bold text-blue">
        <span v-if="shift.start">{{ dayjs(shift.start).format('DD-MM-YYYY') + ' ' }}</span>
        <span v-if="shift.start">{{ dayjs(shift.start).format('H:mm') + ' - ' }}</span>
        <span v-if="shift.end">{{ dayjs(shift.end).format('H:mm') + ' · ' }}</span>
        <span
          v-if="props?.selected_applicant?.first_name"
        >{{ props.selected_applicant.first_name + ' ' }}</span>
        <span
          v-if="props?.selected_applicant?.last_name"
        >{{ props.selected_applicant.last_name + ' · ' }}</span>
        <span v-if="shift.position">{{ shift.position.name }}</span>
      </h2>

      <ApproveHoursForm
        :shift="shift"
        :showing="showing"
        :hide-next-button="hideNextButton"
        @next-row-clicked="sendNextRow"
        @marked-as-no-show="emits('markedAsNoShow')"
      />
    </template>
  </Modal>
</template>
